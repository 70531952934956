import React, { useState } from 'react'
import Link from 'gatsby-link'
import logo from '../images/logo.png'
import Helmet from 'react-helmet'

import {
  Footer,
  FooterText,
  Main,
  Outer,
  HeaderOuter,
  HeaderInner,
  HeaderText,
  Section,
} from '../components/Common.js'

import styled from 'styled-components'

import { MdMenu, MdClose } from 'react-icons/md'
import Media from 'react-media'

const Layout = props => {
  const { title } = props

  const year = new Date().getUTCFullYear()

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Main>
      <HeaderOuter>
        <HeaderInner>
          <LogoWrapper to="/">
            {/* <Logo /> */}
            <LogoImage src={logo} alt="" />
            <HeaderText style={{ fontWeight: 500 }}>
              freelance after five
            </HeaderText>
          </LogoWrapper>

          <Media query="(max-width: 500px)">
            {matches => (
              <>
                {matches && (
                  <ButtonContainer open={menuOpen}>
                    <HeaderButton onClick={() => setMenuOpen(!menuOpen)}>
                      {menuOpen ? <MdClose size={25} /> : <MdMenu size={25} />}
                    </HeaderButton>
                  </ButtonContainer>
                )}
              </>
            )}
          </Media>
        </HeaderInner>

        <Media query="(max-width: 500px)">
          {matches => (
            <>
              {matches && menuOpen && (
                <ExpandedMenu>
                  <ExpandedMenuLink
                    onClick={() => setMenuOpen(!menuOpen)}
                    css={{ borderBottom: '1px solid' }}
                    to="/a-fluff-free-guide-to-pre-freelancing"
                  >
                    Fluff Free Guide
                  </ExpandedMenuLink>
                  <ExpandedMenuLink
                    onClick={() => setMenuOpen(!menuOpen)}
                    css={{ borderBottom: '1px solid' }}
                    to="/subscribe"
                  >
                    Subscribe
                  </ExpandedMenuLink>
                  <ExpandedMenuLink
                    onClick={() => setMenuOpen(!menuOpen)}
                    to="/contact"
                  >
                    Contact
                  </ExpandedMenuLink>
                </ExpandedMenu>
              )}
            </>
          )}
        </Media>
      </HeaderOuter>

      <Outer>
        <Media query="(max-width: 500px)">
          {matches => (
            <>
              {!matches && (
                <Navigation>
                  <NavigationLink to="/a-fluff-free-guide-to-pre-freelancing">
                    Fluff Free Guide
                  </NavigationLink>
                  <NavigationLink to="/subscribe">Subscribe</NavigationLink>
                  <NavigationLink to="/contact">Contact</NavigationLink>
                </Navigation>
              )}
            </>
          )}
        </Media>

        {title && <Helmet title={title} />}

        {/* We can do fancy templete shit here */}

        <Section>{props.children}</Section>
      </Outer>

      <Footer>
        <FooterText>
          © freelance after five {year} <br />
          <span style={{ fontSize: '.7rem' }}>
            no gmo's, gluten or themes were used in the making of this!
          </span>
        </FooterText>

        <Plug href="https://twitter.com/freelanceAfter5">
          by @FreelanceAfter5
        </Plug>
      </Footer>
    </Main>
  )
}

const ExpandedMenu = styled.div`
  width: 100%;
  padding: 20px;
  background: #78aabf;
`

const ExpandedMenuLink = styled(Link)`
  /* line-height: 2rem; */
  display: block;

  color: white !important;
  text-align: center;
  font-size: 2rem;
  text-decoration: none !important;
  /* border-bottom: 1px solid; */
  padding-bottom: 10px;
  padding-top: 10px;
  margin-bottom: 10px;

  &:active {
    background: rgba(255, 255, 255, 0.3);
  }
  /* &:hover {
    background: rgba(255, 255, 255, 0.3);
  } */
  &:focus {
    background: rgba(255, 255, 255, 0.3);
  }
`

const Navigation = styled.nav`
  max-width: 700px;
  display: flex;
  justify-content: space-around;
  margin: auto;
  /* background: #93948c; */
  background: #78aabf;
`

const NavigationLink = styled(Link)`
  text-transform: uppercase;
  padding: 14px 10px 13px;
  width: 100%;
  text-align: center;
  text-decoration: none !important;
  cursor: pointer;
  color: white !important;
  transition: 0.3s;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`

const LogoWrapper = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none !important;
  flex: 1;

  &:hover: {
    opacity: 0.9;
  }
  &:active: {
    opacity: 0.7;
  }
`

const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  text-decoration: none !important;
  flex: 1;
  border: none;
  padding: 4px 8px;
  /* border: 1px solid #78aabf; */
  background: #ea9629;
  border-radius: 3px;
  color: white !important;

  &:hover: {
    opacity: 0.5;
  }

  &:active: {
    opacity: 0.7;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 40px;
  width: 40px;

  ${props =>
    props.open &&
    `
      @media (max-width: 500px) {
        height: 50px;

        button {
          border: none;
          border-radius: 0;
          background: #78aabf;

          &:active{
            background:#ea9629;
          }
        }
      }
  `}
`

const Plug = styled.a`
  position: fixed;
  bottom: 0;
  right: 0;
  border-top-left-radius: 5px;
  border-top: 1px solid #bebebe;
  font-weight: 800;
  border-left: 1px solid #bebebe;
  background: #fff;
  color: #6f6f6f;
  line-height: 30px;
  padding: 0 8px;
  text-decoration: none;
  font-size: 12px;

  @media (max-width: 700px) {
    display: none;
  }
`

const LogoImage = styled.img`
  width: 50px;
  margin: 0;
  margin-right: 10px;
`

export default Layout
