import React from 'react'
import PropTypes from 'prop-types'
import jsonp from 'jsonp'
import styled from 'styled-components'

import { BaseInput, BaseButton } from './Common'

const getAjaxUrl = url => url.replace('/post?', '/post-json?')

class SubscribeForm extends React.Component {
  state = {
    status: null,
    msg: null,
    input: '',
    value: '',
  }

  handleSubmit = e => {
    e.preventDefault()

    const { value } = this.state

    const notValid = !value || value.length < 5 || value.indexOf('@') === -1

    if (notValid) {
      this.setState({
        status: 'error',
        msg: 'Please enter a valid email.',
      })

      return
    }

    this.setState(
      {
        status: 'sending',
        msg: null,
      },
      this.handleSubmitResponse()
    )
  }

  handleSubmitResponse() {
    const { afterSubmit, bypassAlreadySubscribed } = this.props

    const url =
      getAjaxUrl(this.props.action) +
      `&EMAIL=${encodeURIComponent(this.state.value)}`

    const requestOptions = {
      param: 'c',
    }

    jsonp(url, requestOptions, (error, data) => {
      console.log(data)

      if (error) {
        this.setState({
          status: 'error',
          msg: error,
        })
      } else if (data.result !== 'success') {
        //NOTE if we have a person already subed and want to bypass
        //we supress the typical error
        if (bypassAlreadySubscribed && data.msg.match('already subscribed')) {
          console.log('letem pass!!')

          return afterSubmit({ email: this.state.value, error, data })
        }

        this.setState({
          status: 'error',
          msg: data.msg,
        })
      } else {
        this.setState({
          status: 'success',
          msg: data.msg,
          value: '',
        })

        afterSubmit({ email: this.state.value, error, data })
      }
    })
  }

  handleChange = event => {
    this.setState({ value: event.target.value })
  }

  render() {
    const { action, messages, className, style, styles } = this.props

    const { status, msg, value } = this.state

    const isDisabled = status === 'sending' || status === 'success'

    return (
      <div className={className} style={style}>
        <form action={action} style={{ margin: 0 }} method="post" noValidate>
          <SubscribeActions>
            <BaseInput
              style={{
                margin: '0 auto 15px auto',
                padding: 10,
                width: '300px',
                textAlign: 'center',
              }}
              ref={node => (this.input = node)}
              type="email"
              // defaultValue=""
              name="EMAIL"
              required={true}
              placeholder={messages.inputPlaceholder}
              value={value}
              onChange={this.handleChange}
            />

            {!isDisabled && (
              <BaseButton
                style={{
                  padding: '10px',
                  width: '250px',
                  margin: 'auto',
                  marginBottom: 15,
                  fontWeight: 400,
                }}
                disabled={isDisabled}
                onClick={this.handleSubmit}
                type="submit"
                children={messages.buttonText}
              />
            )}
          </SubscribeActions>

          {status === 'sending' && (
            <MailStatus style={styles.sending} children={messages.sending} />
          )}

          {status === 'success' && (
            <SuccessText
              style={styles.success}
              children={messages.success || msg}
            />
          )}

          {/* NOTE if they are already subed, lets consider it a success */}
          {status === 'error' && (
            <>
              {msg.match('already subscribed') ? (
                <MailStatus
                  style={styles.success}
                  dangerouslySetInnerHTML={{
                    __html: msg
                      .replace(
                        'Freelance After Five.',
                        'Freelance After Five.<br/>'
                      )
                      .replace('<a', '<a target="_blank"'),
                  }}
                />
              ) : (
                <MailStatus style={styles.error} children={messages.error} />
              )}
            </>
          )}
        </form>
      </div>
    )
  }
}

SubscribeForm.propTypes = {
  messages: PropTypes.object,
  styles: PropTypes.object,
}

const defaultResponseStyles = {
  margin: '10px 0',
  padding: 10,
}

SubscribeForm.defaultProps = {
  action:
    'https://Darryldexter.us8.list-manage.com/subscribe/post?u=c0e34cfb0935dee44a90a043f&amp;id=ca1df3c47c',
  messages: {
    inputPlaceholder: 'Email address',
    buttonText: 'Send me advice!',
    sending: 'Sending.',
    success: 'Awesome, thanks! Be ready to receive some tips!',
    error: 'Uh, oh, Something broke. Refresh and try again please',
  },
  styles: {
    sending: {
      fontSize: 18,
      border: '1px solid #d8eacc',
      color: 'auto',
      ...defaultResponseStyles,
    },
    success: {
      fontSize: 18,
      background: '#d8eacc',
      textAlign: 'center',
      ...defaultResponseStyles,
    },
    error: {
      fontSize: 18,
      background: '#eacccc',
      ...defaultResponseStyles,
    },
  },
}

export default SubscribeForm

export const SubscribeActions = styled.div`
  display: flex;
  flex-direction: column;
`

export const SuccessText = styled.p`
  font-size: 18px;
  color: #157715;
  margin: 0px;
  text-align: left;
  padding: 10px 0;
`

export const MailStatus = styled.p`
  text-align: center;
  /* opacity: 0.6; */
`
