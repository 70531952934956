//@flow

import React, { Component } from 'react'
import Helmet from 'react-helmet'
import config from '../../config'
import siteImage from '../static/site-image.png'
const Entities = require('html-entities').AllHtmlEntities

const entities = new Entities()

// //https://stackoverflow.com/a/17076120/240993
// //need to remove the html shit from the except...
// function decodeHTMLEntities(text) {
//     var entities = [
//         ['amp', '&'],
//         ['apos', "'"],
//         ['#x27', "'"],
//         ['#x2F', '/'],
//         ['#39', "'"],
//         ['#47', '/'],
//         ['lt', '<'],
//         ['gt', '>'],
//         ['nbsp', ' '],
//         ['quot', '"'],
//     ]

//     for (var i = 0, max = entities.length; i < max; ++i)
//         text = text.replace(
//             new RegExp('&' + entities[i][0] + ';', 'g'),
//             entities[i][1]
//         )

//     return text
// }

class SEO extends Component {
  render() {
    const { postNode, postPath, postSEO } = this.props
    let title
    let description
    let image
    let postURL

    if (postSEO) {
      const postMeta = postNode
      title = postMeta.title
      description = postMeta.description
        ? postMeta.description
        : postNode.excerpt

      image = postMeta.cover
      postURL = config.siteUrl + config.pathPrefix + postPath
    } else {
      title = config.siteTitle
      description = config.siteDescription
      image = config.siteLogo
    }

    //dumb wordpress likes to send all types of markup in the excerpt...
    description = entities.decode(description.replace(/<(?:.|\n)*?>/gm, ''))
    title = entities.decode(title)

    // const ogImage =
    //   postNode && postNode.better_featured_image
    //     ? postNode.better_featured_image.media_details.sizes.large.source_url
    //     : siteImage

    const ogImage =
      postNode &&
      postNode.featured_media &&
      postNode.featured_media.localFile.childImageSharp.original.src

    // console.log(ogImage)

    const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix
    // image = config.siteUrl + realPrefix + image
    image = ogImage
    const blogURL = config.siteUrl + config.pathPrefix

    const schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'WebSite',
        url: blogURL,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
      },
    ]

    if (postSEO) {
      schemaOrgJSONLD.push([
        {
          '@context': 'http://schema.org',
          '@type': 'BreadcrumbList',
          itemListElement: [
            {
              '@type': 'ListItem',
              position: 1,
              item: {
                '@id': postURL,
                name: title,
                image,
              },
            },
          ],
        },
        {
          '@context': 'http://schema.org',
          '@type': 'BlogPosting',
          url: blogURL,
          name: title,
          alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
          headline: title,
          image: {
            '@type': 'ImageObject',
            url: image,
          },
          description,
        },
      ])
    }

    return (
      <Helmet>
        {/* General tags */}
        <meta name="description" content={description} />
        <meta name="image" content={image} />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:url" content={postSEO ? postURL : blogURL} />

        {postSEO ? <meta property="og:type" content="article" /> : null}

        <meta property="og:site_name" content={'freelance after five'} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        {/* https://stackoverflow.com/a/32729433 */}
        <meta property="og:image" content={config.siteUrl + image} />
        <meta
          property="fb:app_id"
          content={config.siteFBAppID ? config.siteFBAppID : ''}
        />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary" />

        <meta
          name="twitter:creator"
          content={config.userTwitter ? config.userTwitter : ''}
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={config.siteUrl + image} />
      </Helmet>
    )
  }
}

export default SEO
