module.exports = {
    blogPostDir: 'sample-posts', // The name of directory that contains your posts.
    siteTitle:
        'Freelance After Five: Tips and secrects to a better freelancing lifestyle', // Site title.
    siteTitleAlt:
        'Freelance After Five: Tips and secrects to a better freelancing lifestyle', // Alternative site title for SEO.
    siteLogo: '/images/logo.png', // Logo used for SEO and manifest.
    siteUrl: 'https://freelanceafterfive.com', // Domain of your website without pathPrefix.
    pathPrefix: '/', // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
    siteDescription:
        'Helping freelancers of all kinds become better at freelancing and land the clients they only dreamed of. The goal is to make freelancing simple and one tip at a time.', // Website description used for RSS feeds/meta description tag.
    siteRss: '/rss.xml', // Path to the RSS file.
    siteFBAppID: '1825356251115265', // FB Application ID for using app insights
    googleAnalyticsID: 'UA-47311644-5', // GA tracking ID.
    disqusShortname: 'https-vagr9k-github-io-gatsby-advanced-starter', // Disqus shortname.
    postDefaultCategoryID: 'Tech', // Default category for posts.
    userName: 'Darryl D.', // Username to display in the author segment.
    userTwitter: 'darryldexter', // Optionally renders "Follow Me" in the UserInfo segment.
    userLocation: 'Detroit, MI', // User location to display in the author segment.
    userAvatar: 'https://api.adorable.io/avatars/150/test.png', // User avatar to display in the author segment.
    userDescription:
        'UI Designer/Developer. Consultant. INTJ. Darrylpreneur (kinda like entrepreneur).', // User description to display in the author segment.
    // Links to social profiles/projects you want to display in the author segment/navigation bar.
    userLinks: [
        {
            label: 'GitHub',
            url: 'https://github.com/DarrylD',
            iconClassName: 'fa fa-github',
        },
        {
            label: 'Twitter',
            url: 'https://twitter.com/darryldexter',
            iconClassName: 'fa fa-twitter',
        },
        {
            label: 'Email',
            url: 'mailto:darryl.dex@gmail.com',
            iconClassName: 'fa fa-envelope',
        },
    ],
    copyright: 'Copyright © 2017. Darryl Dexter', // Copyright string for the footer of the website and RSS feed.
    themeColor: '#78abbf', // Used for setting manifest and progress theme colors.
    backgroundColor: '#e0e0e0', // Used for setting manifest background color.
}
