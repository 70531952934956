import Link from 'gatsby-link'

import styled, { css } from 'styled-components'

export const Wrapper = styled.section`
  padding: 4em;
  background: papayawhip;
`

export const HeroOuter = styled.div`
  background: #78abbf;
  width: 100%;
  margin-bottom: 105px;
  padding: 100px 0;
  border-radius: 4px;
`
export const HeroInner = styled.div`
  width: 80%;
  max-width: 900px;
  margin: auto;
  color: #fff;
  font-family: open sans;
  text-align: center;
  font-weight: 100;
`

export const HeroText = styled.h1`
  color: #fff;
  line-height: 1.2em;
  font-size: 72px;
  margin-bottom: 40px;
  font-size: 72px;
  font-weight: 100;
  margin-top: 0;
  margin-top: 0px;
  font-family: open sans;
`

export const Text = styled.p`
  text-align: center;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.5em;
  color: #444;
  font-weight: 100;
  color: #5a5a5a;
`

export const HeroSub = styled.p`
  font-size: 20px;
`

export const Footer = styled.p`
  text-align: center;
  /* color: #ccc; */
  margin: 0;
  line-height: 6em;

  /* background: #444; */
  width: 100%;
`
export const FooterText = styled.div`
  text-align: center;
  color: #8f8f8f;
  margin: 0;
  /* font-weight: 100; */
  font-size: 1rem;
  line-height: 1.5em;
  padding: 2rem 0;
`

export const Section = styled.section`
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  /* width: 80%; */
  max-width: 700px;

  @media (max-width: 700px) {
    width: 100%;
    padding: 0 20px;
  }
`

export const SectionTitle = styled.h2`
  color: #78abbf;
  text-align: center;
  font-size: 2rem;
  font-weight: 100;
  font-family: open sans;
  margin-bottom: 10px;
`

export const SectionSub = styled.h3`
  color: #78abbf;
  text-align: center;
  margin-bottom: 20px;
  font-size: 1rem;
  font-family: open sans;
  font-weight: 400;
  text-transform: uppercase;
  opacity: 0.7;
  font-size: 0.8rem;
  letter-spacing: 0.05em;
`

export const Main = styled.div`
  font-family: open sans;
`

export const StyledLink = styled(Link)`
  display: block;
  color: #78abbe;
  margin-bottom: 6px;
  text-decoration: none;
`

export const Outer = styled.div`
  padding: 40px;
  padding-top: 20px;

  @media (max-width: 700px) {
    padding: 40px 0;
  }
`

export const PostBlock = styled.div`
  margin-bottom: 60px;
`

export const Title = styled.h3`
  margin: 0;
  margin-bottom: 15px;
  font-size: 25px;
`

export const PostTitle = styled(Title)`
  margin: 0;
  font-size: 30px;

  ${props =>
    props.bigText &&
    css`
      border: 15px solid;
      font-size: 4rem;
      padding: 20px;

      @media (max-width: 700px) {
        font-size: 3rem;
        padding: 10px;
        border-width: 5px;
      }
    `}
`

export const PostText = styled.div`
  color: #5d5c5c;
  margin-bottom: 5px;
  font-weight: initial;
  line-height: 1.7em;
  font-size: 21px;

  font-family: roboto;

  a {
    font-weight: 700;
    text-decoration: none;
    border-bottom: 3px solid;

    &:hover {
      border-bottom: 3px dotted;
    }
  }
`

export const PostImage = styled.div`
  height: 330px;
  background: rgba(120, 170, 191, 0.13);
  margin: 10px 0px;
  border-radius: 3px;

  @media (max-width: 700px) {
    height: 150px;
    background-position: center center !important;
  }
`

export const PostReadMore = styled(StyledLink)`
  margin-top: 0;
  /* font-size: 12px; */
  text-decoration: none !important;
  border-bottom: 3px solid;
  font-weight: 700;
  display: inline-block;
`

export const HeaderOuter = styled.div`
  width: 100%;
  border-top: 2px solid #78abbe;
  /* height: 70px; */
  /* position: fixed; */
  background: rgba(255, 255, 255, 0.97);
  top: 0;
  z-index: 999;

  @media (max-width: 700px) {
    /* position: absolute; */
  }
`
export const HeaderInner = styled(Section)`
  width: 700px;
  height: 100%;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    justify-content: center;
    width: 100%;
  }
`

export const HeaderText = styled.h3`
  color: #78abbf;
  line-height: 1.2em;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: 100;
  margin-top: 0;
  margin-top: 0px;
  font-family: open sans;
`
export const Logo = styled.div`
  padding: 1px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #78abbf;
  margin-right: 10px;
`

export const BigQuote = styled.div`
  text-align: center;
  font-style: italic;
  color: #868686;
  font-size: 22px;
  font-weight: 100;
  width: 300px;
  margin: auto;
  line-height: 1.5em;
  margin-top: 80px;
  margin-bottom: 80px;

  @media (max-width: 500px) {
    margin-top: 0px;
  }
`

export const BaseInput = styled.input`
  border: 1px solid #d7d7d7;
  font-size: 18px;
  line-height: 1.5em;
  height: 50px;
  min-width: 200px;
  margin-right: 15px;
  padding-right: 16px;
  padding-bottom: 8px;
  padding-left: 16px;
  flex: 2;
  outline: none !important;

  color: #676767;

  @media (max-width: 700px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`

export const BaseButton = styled.button`
  padding: 1px;
  flex: 1;
  background: #78aabf;
  border: 0 !important;
  color: white !important;
  outline: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.7;
  }

  @media (max-width: 700px) {
    height: 40px;
  }
`

export const Divider = styled.hr`
  background: #78aabf;
  height: 4px;
`
